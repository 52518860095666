import * as React from "react";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Toolbar
} from "@mui/material";
import StyledDrawer from "./Drawer";
import Drawer from "@mui/material/Drawer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AddLinkIcon from "@mui/icons-material/AddLink";
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import ListIcon from "@mui/icons-material/List";
import { AccountBox, Logout } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { useHttp } from "../hooks/http.hook";

function SideBar({ open, toggleDrawer, drawerWidth, isMobile }) {

  const navigate = useNavigate()
  const auth = React.useContext(AuthContext)
  const { request } = useHttp()

  const logoutHandler = async () => {
    auth.logout();
    //navigate("/");
    try {
      console.log("logging out...");
      await request({
        url: "/api/auth/logout",
        method: "POST",
        body: { token: auth.refreshToken },
      });
    } catch (e) {
      console.log(e);
    }
  }

  // React.useEffect(() =>
  // {
  //   console.log(isMobile, open)
  //   if (isMobile && open) 
  //     toggleDrawer();
  // }, [isMobile, open, toggleDrawer])
 
  const mainListItems = (
    <React.Fragment>
      <ListItemButton
        style={{ display: auth.role === "admin" ? "flex" : "none" }}
        onClick={() => navigate("/dashboard")}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Дашборд" />
      </ListItemButton>
      <ListItemButton onClick={() => navigate("/create")}>
        <ListItemIcon>
          <AddLinkIcon />
        </ListItemIcon>
        <ListItemText primary="Создать" />
      </ListItemButton>
      <ListItemButton onClick={() => navigate("/links")}>
        <ListItemIcon>
          <ListIcon />
        </ListItemIcon>
        <ListItemText primary="Мои ссылки" />
      </ListItemButton>
      <ListItemButton onClick={() => navigate("/streamgen")}>
        <ListItemIcon>
          <SlowMotionVideoIcon />
        </ListItemIcon>
        <ListItemText primary="Трансляции" />
      </ListItemButton>
    </React.Fragment>
  );

  const secondaryListItems = (
    <React.Fragment>
      <ListSubheader component="div" inset>
        Мой профиль
      </ListSubheader>
      <ListItemButton onClick={() => navigate("/profile")}>
        <ListItemIcon>
          <AccountBox />
        </ListItemIcon>
        <ListItemText primary="Настройки"  />
      </ListItemButton>
      <ListItemButton onClick={logoutHandler}>
        <ListItemIcon>
          <Logout />
        </ListItemIcon>
        <ListItemText primary="Выйти" />
      </ListItemButton>
    </React.Fragment>
  );

  const drawerContent = (
    <>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
      >
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        {mainListItems}
        <Divider sx={{ my: 1 }} />
        {secondaryListItems}
      </List>
    </>
  );

  const mobileDrawerContent = (
    <Box
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
      sx={{
        width: 250,
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
      >
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        {mainListItems}
        <Divider sx={{ my: 1 }} />
        {secondaryListItems}
      </List>
    </Box>
  );

  return (
    <>
      {!isMobile && (
        <StyledDrawer
          variant="permanent"
          open={open && !isMobile}
          // sx={{
          //   display: { xs: "none", sm: "flex" },
          // }}
          drawerWidth={drawerWidth}
          isMobile={isMobile}
        >
          {drawerContent}
        </StyledDrawer>
      )}

      {isMobile && (
        <Drawer
          variant="temporary"
          anchor={'left'}
          open={open}
          onClose={toggleDrawer}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: '240px',
            },
          }}
        >
          {mobileDrawerContent}
        </Drawer>
      )}
    </>
  );
}

export default SideBar