import { Navigate, Route, Routes } from "react-router-dom"
import { CreatePage } from "./pages/CreatePage";
import { DashboardPage } from "./pages/DashboardPage";
import { LinksPage } from "./pages/LinksPage";
import AuthPage from "./pages/AuthPage";
import { ProfilePage } from "./pages/ProfilePage";
import { StreamGenPage } from "./pages/StreamGenPage";

export const useRoutes = (isAuthenticated) => {

    if (isAuthenticated) {
        return (
          <Routes>
            <Route path="/create" exact element={<CreatePage />} />
            <Route path="/links" exact element={<LinksPage />} />
            <Route path="/dashboard" exact element={<DashboardPage />} />
            <Route path="/profile" exact element={<ProfilePage />} />
            <Route path="/streamgen" exact element={<StreamGenPage />} />
            <Route path="*" element={<Navigate to="/create" />} />
          </Routes>
        );
    }

    return (
      <Routes>
        <Route path="/auth" exact element={<AuthPage />} />
        <Route path="*" element={<Navigate to="/auth" />} />
      </Routes>
    );
}