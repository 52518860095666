import React, { useCallback, useContext, useEffect, useState } from "react";
import { PageContext } from "../context/PageContext";
import { Alert, AlertTitle, Box, Button, Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField } from "@mui/material";
import { useHttp } from "../hooks/http.hook";
import { copyTextToClipboard } from "../helpers";

export const StreamGenPage = () => {

  const initialButtonTitle = "Создать ссылку для встраивания плеера"
  const page = useContext(PageContext)
  const [ytlink, setYtLink] = useState("")
  const [vklink, setVkLink] = useState("")
  const [rtlink, setRtLink] = useState("")
  const [btnTitle, setBtnTitle] = useState(initialButtonTitle)
  const [btnColor, setBtnColor] = useState("primary")
  const [tglink, setTgLink] = useState("https://t.me/+k3h-VhJ6aAo2YmRi");
  const [playlink, setPlayLink] = useState("")
  const [customCode, setCustomCode] = useState("")
  const { loading, request, error, clearError, cancelRequests, resumeRequests } = useHttp()

  const handleSubmit = async (event) => {
    event.preventDefault()
    //await createShortLink()
  }

  const copyPlayerLinkToClipboard = useCallback(link => {
    const ok = copyTextToClipboard(link);
    setTimeout(() => {
      setBtnTitle(
        ok
          ? "Создано и скопировано в буфер обмена"
          : "Создано, но скопировать не вышло, копируйте сами!"
      );
      setBtnColor(
        ok
          ? "success"
          : "primary"
      )
      setTimeout(() => {
        setBtnTitle(initialButtonTitle)
        setBtnColor("primary")
      }, 1500);
    }, 1);
  }, [])

  const createPlayerLink = useCallback(async () => {
    let link = "";

    let ytprep = ytlink;
    let temp = ytlink.match(/v=([\w-_]{8,})/);
    if (!temp) temp = ytlink.match(/\w\/([\w-_]{8,})/);
    if (temp) ytprep = temp[1];

    let rtprep = rtlink;
    temp = rtlink.match(/private\/(.*)|[\w-_]{16,}\/\?p=[\w-_]+|[\w-_]{16,}/);
    if (temp) rtprep = temp[1] ?? temp[0];
    rtprep = encodeURIComponent(rtprep);

    // <iframe src="https://vk.com/video_ext.php?oid=176028151&id=456239566&hash=e8a94b725a0fd892&autoplay=1" width="426" height="240" allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" frameborder="0" allowfullscreen></iframe>
    let vkprep = vklink;
    temp = vklink.match(/http[^\s"']+/);
    if (temp) vkprep = temp[0];
    if (vkprep.indexOf("?") >= 0)
      vkprep = vkprep.substring(vkprep.indexOf("?") + 1);
    const vkParams = new URLSearchParams(vkprep);
    let vkParts = [];
    temp = vkParams.get("oid");
    if (temp) vkParts.push("oid=" + temp);
    temp = vkParams.get("id");
    if (temp) vkParts.push("id=" + temp);
    temp = vkParams.get("hash");
    if (temp) vkParts.push("hash=" + temp);
    vkprep = vkParts.join("&");
    vkprep = encodeURIComponent(vkprep);

    let tgprep = tglink;
    temp = tglink.match(/[\w-+_]{8,}/);
    if (temp) tgprep = temp[0];
    tgprep = encodeURIComponent(tgprep);

    link = `https://t3chn0pr13st.github.io/csplayer/?yt=${ytprep}&rt=${rtprep}&vk=${vkprep}&tg=${tgprep}&autoload=1`;

    setPlayLink(link);
    await copyPlayerLinkToClipboard(link);
  }, [ytlink, vklink, rtlink, tglink, copyPlayerLinkToClipboard]);

  useEffect(() => {
    resumeRequests();
    page.setTitle("Генератор ссылки для трансляции");
    return () => cancelRequests();
  }, [page, cancelRequests, resumeRequests])

  return (
    <>
      <Box component="form" spacing={2} onSubmit={handleSubmit}>
        <Grid container spacing={{ xs: 2, sm: 2 }}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Вставьте код для встраивания iframe VK.Video, либо src-атрибут из iframe"
              name="vklink"
              value={vklink ?? ""}
              onChange={(e) => setVkLink(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Вставьте ссылку на YouTube, либо ID"
              name="ytlink"
              value={ytlink ?? ""}
              onChange={(e) => setYtLink(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Вставьте ссылку на RuTube, либо ID"
              name="rtlink"
              value={rtlink ?? ""}
              onChange={(e) => setRtLink(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Вставьте пригласительную ссылку на чат в телеграмм"
              name="tglink"
              value={tglink ?? ""}
              onChange={(e) => setTgLink(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              color={btnColor}
              variant="contained"
              type="submit"
              sx={{ width: "100%", p: "12px 30px" }}
              onClick={createPlayerLink}
              disabled={loading}
            >
              {btnTitle}
            </Button>
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Alert severity="error">
                <AlertTitle>Произошла ошибка</AlertTitle>
                {error.errors ? error.errors[0]?.msg : error.message}
              </Alert>
            </Grid>
          )}
          {playlink && (
            <Grid item xs={12}>
              <TextField value={playlink} fullWidth />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
}
